import React from "react";
import { Button, Card, CardMedia } from "@material-ui/core";
import { Header } from "./CardHeader";
import { CardInfo } from "./CardInfo";
import styled from "styled-components";
import { withResponsiveTarget } from "../withResponsiveTarget";
import ResponsivePicture from "../../Common/ResponsivePicture";
import constants, {
  DEFAULT_PRODUCER_LIST_COVER_2,
} from "../../Common/constants";

const ResponsiveBtnLink = withResponsiveTarget(Button);

const GroupBadge = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;
  width: fit-content;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  position: absolute;
  top: 65px;
  right: 0px;
  padding: 6px 20px;
  border-radius: 26px 0px 0px 26px;
`;

const styles = {
  card: {},
  media: {
    backgroundColor: "red",
    height: 180,
    // paddingTop: '56.25%', // 16:9
  },
  linkContainer: {
    fontWeight: "normal",
    padding: 0,
    display: "block",
    textTransform: "none",
  },
};
export class UserCard extends React.PureComponent {
  _onMouseEnter = () => {
    this.props.onHoverChange(this.props.user.id);
  };
  _onMouseLeave = () => {
    this.props.onHoverChange(null);
  };
  render() {
    let { user, isSelectedFromMap } = this.props;
    const bannerUrl = user.bannerUrl || DEFAULT_PRODUCER_LIST_COVER_2;

    const path = user.isGroup ? `group` : `producer`;
    const link = `https://commande.kuupanda.com/${path}/${user.clientId}/particulier`;

    return (
      <Card
        elevation={isSelectedFromMap ? 6 : 2}
        style={styles.card}
        onMouseEnter={this._onMouseEnter}
        onMouseLeave={this._onMouseLeave}
      >
        <ResponsiveBtnLink
          href={link}
          style={styles.linkContainer}
        >
          <Header
            title={user.farmInfo.name}
            subheader={user.farmInfo.address.locality}
            avatar={user.profilePicture}
            avatarAlt={user.displayName}
          />
          {user.isGroup && (
            <GroupBadge>
              Regroupement <br /> de producteurs
            </GroupBadge>
          )}
          <CardMedia
            style={styles.media}
            component={() => (
              <ResponsivePicture
                fitType="cover"
                height={180}
                sizeQuery="(min-width: 1650px) 20vw,(min-width: 600px) and (max-width: 1649px) 45vw, 90vw"
                baseImgUrl={bannerUrl}
              />
            )}
            title={user.storeName}
          />
          <CardInfo
            categories={user.categories}
            tags={user.tags}
            productCount={user.productCount}
            doesDelivery={user.doesDelivery}
          />
        </ResponsiveBtnLink>
      </Card>
    );
  }
}
